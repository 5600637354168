<template>
  <div class="repeatable-values"
    :class="`repeatable-values--n-cols-${nCols} repeatable-values--${hasIndex ? 'has-index' : 'no-index'}`"
  >

    <div class="repeatable-values-row repeatable-values-row--head"
      v-if="labels"
    >
      <div class="repeatable-values-col repeatable-values-col--index"
      v-if="hasIndex"
      >
        <div class="label text-align-center">#</div>
      </div>
      <div class="repeatable-values-col"
        v-for="(label, i) in labels" :key="i"
      >
        <div class="label">{{ label }}</div>
      </div>
    </div>

    <template v-if="values">
      <div class="repeatable-values-row"
        v-for="(value, i) in values" :key="i"
      >
        <div class="repeatable-values-col repeatable-values-col--index"
          v-if="hasIndex"
        >
          <div class="repeatable-value repeatable-value--index">{{ i + 1 }}</div>
        </div>
        <div class="repeatable-values-col"
          v-for="(val, j) in value" :key="j"
        >
          <div class="repeatable-value">{{ val }}</div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: 'RepeatableValues',

  props: {
    values: {
      type: Array,
      // required: true,
    },
    labels: {
      type: Array,
      // required: true,
    },
    nCols: {
      type: [Number, String],
      default: 3,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>

.repeatable-values-row {
  display: table-row;

  display: grid;
  gap: $gutter-half;
  margin-bottom: $gutter-eighth;

  &.repeatable-values-row--head {
    display: none;
  }

  @include bp('xs') {
    &.repeatable-values-row--head {
      display: grid;
    }

    .repeatable-values--n-cols-1 & { grid-template-columns: 1fr; }
    .repeatable-values--n-cols-2 & { grid-template-columns: 1fr 1fr; }
    .repeatable-values--n-cols-2 & { grid-template-columns: 1fr 1fr 1fr; }
    .repeatable-values--n-cols-4 & { grid-template-columns: 1fr 1fr 1fr 1fr; }

    .repeatable-values--has-index.repeatable-values--n-cols-1 & { grid-template-columns: $gutter 1fr; }
    .repeatable-values--has-index.repeatable-values--n-cols-2 & { grid-template-columns: $gutter 1fr 1fr; }
    .repeatable-values--has-index.repeatable-values--n-cols-2 & { grid-template-columns: $gutter 1fr 1fr 1fr; }
    .repeatable-values--has-index.repeatable-values--n-cols-4 & { grid-template-columns: $gutter 1fr 1fr 1fr 1fr; }

    &:not(:first-child) {
      :deep(label),
      :deep(.label) {
        display: none;
      }
    }
  }

  .label {
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    color: $body-color-base;
  }
}

.repeatable-values-col {
  // display: table-cell;
  @include v-padding($gutter-eighth);
  background-color: $color-gray-lightest;
  // text-overflow: ellipsis;
  // overflow: hidden;

  .repeatable-values-row--head &,
  &.repeatable-values-col--index {
    background-color: transparent;
  }

  @include bp('xs') {
    @include h-padding($gutter-quarter);

    .repeatable-values-row--head & {
      @include h-padding(0);
    }
  }
}

.repeatable-value {}

.repeatable-value--index {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  display: inline-block;
  @include size(2rem);
  line-height: 2rem;
  background-color: $color-gray-darkest;
  color: white;
  text-align: center;
  border-radius: 1rem;
}

</style>
